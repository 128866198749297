import React from 'react'
import { motion } from 'framer-motion'
import "./list.css"

const ListItems = (props) => {
  return (
    <>
    
      {props.items.map((item, index) => (
        <div>
          <motion.h1 key={'link-' + index} whileInView={{x: [100, 0], opacity:[0, 1]}} transition={{ duration: 1, ease: 'easeInOut'}}>
            {item.title}
          </motion.h1>
        {item.lists.map((list, index) => (
        <motion.ul className='ul' whileInView={{x: [100, 0], opacity:[0, 1]}} transition={{ duration: 1, ease: 'easeInOut'}}>
          <li className='li' key={'list-' + index}>
            {list}
          </li>
        </motion.ul>
        ))}
        </div>
      ))}
    </>
  )
}


export default ListItems