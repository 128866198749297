import React from 'react';
import { motion } from 'framer-motion'
import './App.css';
import logo from "./assets/logo.png";
import ListItems from './comonents/ListItems';

function App() {
  const importItems = [
    {
      title:'Import', 
      lists: [
        "Chemicals and Chemical Products for industrial purposes", 
        "Metal materials & non metal scraps"
      ]
    }];

    const exportItems = [
      {
        title:'Export', 
        lists: [
          "Oilseeds, Cereals, Pulses, Peper and Spices"
        ]
      }];
    
  return (
    <div className='app__container'>
      <motion.div className='app__logo' whileInView={{x: [-100, 0], opacity: [0, 1]}} transition={{ duration: 1, ease: 'easeInOut'}}>
        <img src = { logo } alt="logo"></img>
      </motion.div>
      <div className='app__services'>
        <div className='app__services-export'>
          <ListItems items = {importItems}/>
        </div>
        <div className='app__services-import'>
          <ListItems items = {exportItems} />
        </div>
      </div>
    </div>                                                                                      
  );
}

export default App;
